import './App.css';
import HompageComponent from './components/homepage';

function App() {
  return (
    <div className="App">
      <HompageComponent></HompageComponent>
    </div>
  );
}

export default App;
