import './homepage.css';
import LOGO from '../../assets/images/logo-small.jpg';
import DUMMYIMG from '../../assets/images/demo-img.jpg';
import SHREYAIMG from '../../assets/images/shreya.jpeg';
// import AVATAR from '../../assets/images/avatar.png';
import ARTICLE1 from '../../assets/images/article1.jpg';
import POSTER1 from '../../assets/images/poster1.jpg';
import POSTER2 from '../../assets/images/poster2.jpg';

const HompageComponent = () => {
    return (
        <div className='homepageWrapper'>
            <div className='navbar'>
                <div className='logo-box'>
                    <img src={LOGO} alt="claps_media_logo" className='navbar-logo'></img>
                    <p className='logo-text'>Fashion Lawyer India</p>
                </div>
                <div className='navbar-items'>
                    <span className='navbar-link'><a href='#intro'>About Us</a></span>
                    <span className='navbar-link'><a href='#services'>Services</a></span>
                    <span className='navbar-link'><a href='#team'>Team</a></span>
                    <span className='navbar-link'><a href='#gallery'>Articles</a></span>
                    <span className='navbar-link'><a href='#contact'>Contact Us</a></span>
                </div>
            </div>
            <div className='intro-section' id='intro'>
                <div className='intro-box'>
                    <h1>
                        A little about US
                    </h1>
                    <hr className='hr'></hr>
                    <p>
                        As a business entrepreneur you may be interested in learning more about
                        the expertise of fashion lawyers in licensing and brand development.
                        Fashion lawyers specialize in a wide range of legal services related to the
                        fashion industry. From negotiating and drafting license agreements to
                        providing advice on brand development, fashion lawyers have the
                        knowledge and experience to assist clients in all aspects of their legal
                        needs. When it comes to licensing, fashion lawyers are experts in advising
                        clients on the terms of their agreements. This includes understanding
                        existing copyright, trademark, and patent laws, as well as the complexities
                        of negotiating and drafting contracts.
                    </p>

                    <p>
                        Additionally, fashion lawyers can
                        provide advice on how to protect a client's brand and create a strategy for
                        successful partnerships.
                    </p>
                    <p className='bold-text'>
                        <q><cite>We deliver it as we promise it.</cite></q>
                    </p>
                </div>
            </div>
            <div className='services-section' id='services'>
                <div className='services-box'>
                    <h1>Services</h1>
                    <hr className='hr'></hr>
                    <div className='services-inner-box'>
                        <div className='services-card'>
                            <img src={DUMMYIMG} alt="claps-media"></img>
                            <div className='py-2'>
                                <span className='services-title'>Brand Development & Protection</span>
                                <span className='service-body'>
                                    Fashion lawyers have a deep understanding of the legal and regulatory issues that affect the fashion industry.
                                    They can help you navigate the complexities of the law, so that you can make informed decisions about licensing agreements,
                                    brand protection, and other key legal matters. They can also help you develop and protect your brand,
                                    ensuring that its value and longevity are safeguarded.
                                </span>
                            </div>
                        </div>
                        <div className='services-card'>
                            <img src={DUMMYIMG} alt="claps-media"></img>
                            <div className='py-2'>
                                <span className='services-title'>Licensing Agreements</span>
                                <span className='service-body'>
                                    Fashion lawyers also provide counsel on matters such as copyright and trademark protection,
                                    intellectual property protection, and infringement. They're knowledgeable about the fashion
                                    industry, making them well-equipped to advise on industry-specific legal issues. They can provide guidance on negotiating and drafting contracts, ensuring that all parties
                                    involved are protected.
                                </span>
                            </div>
                        </div>
                        <div className='services-card'>
                            <img src={DUMMYIMG} alt="claps-media"></img>
                            <div className='py-2'>
                                <span className='services-title'>Copyright & Trademark</span>
                                <span className='service-body'>
                                    From negotiating and drafting license agreements to providing advice on brand development,
                                    fashion lawyers have the knowledge and experience to assist clients in all aspects of their legal needs.
                                    They are well-versed in the latest trends, regulations, and the international business climate,
                                    allowing them to provide clients with reliable advice on brand development.
                                </span>
                            </div>
                        </div>
                        <div className='services-card'>
                            <img src={DUMMYIMG} alt="claps-media"></img>
                            <div className='py-2'>
                                <span className='services-title'>Drafting & Infringements</span>
                                <span className='service-body'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.
                                </span>
                            </div>
                        </div>
                        <div className='services-card'>
                            <img src={DUMMYIMG} alt="claps-media"></img>
                            <div className='py-2'>
                                <span className='services-title'>IIP Protection</span>
                                <span className='service-body'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='team-section' id='team'>
                <div className='team-box'>
                    <h1>Meet The Team</h1>
                    <hr className='hr'></hr>
                    <div className='team-details'>
                        <div className='team-description'>
                            <img src={SHREYAIMG} alt="claps-media-image"></img>
                            <div className='team-content'>
                                <p>
                                    She is a legal professional based in India with a specialization in Fashion Law from the Fashion
                                    Law Institute, Fordham University New York. As a cross border lawyer,
                                    she cater to clients in both UAE and India. My expertise lies in Corporate Law,
                                    Intellectual Property Law, Labor law, and Prevention of Sexual Harassment of Women at Workplace.
                                </p>
                                <p>
                                    She is a member of the Bar Council of Delhi and the Bar Council of India and hold a good standing
                                    in both organizations. Additionally, a member of the Delhi High Court Bar Association
                                    and is qualified to practice in the High Court of Delhi and all other courts in Delhi.
                                </p>
                                <p>
                                    She has authored a book titled “But What Were you Wearing? - An extensive book on Prevention of
                                    Sexual Harassment of Women at Workplace”, which is available on Amazon Kindle (ASIN- B08TH9MQ4N).
                                    She has also published several journal articles, book reviews, and book chapters, and
                                    is on the Editorial Board of Legal Desire- Media and Insights.
                                </p>
                                <p>
                                    With a strong focus on patents, trademarks, copyright, and the designs Act,
                                    she has considerable experience in IP advisory. She regularly advises clients on the formation
                                    of establishments, compliances, intellectual property rights, anti-competitive trade practices,
                                    labor issues, and more. I cater to start-ups and international brands across a range of industries,
                                    including social media, food, media, sports, entertainment, luxury, fashion, and healthcare.
                                </p>
                                <div className='team-icons'>
                                    {/* <i className='fa fa-vimeo-square'></i> */}
                                    <i className='fa fa-linkedin-square'></i>
                                    {/* <i className='fa fa-youtube-play'></i> */}
                                    <i className='fa fa-twitter-square'></i>
                                    <i className='	fa fa-instagram'></i>
                                </div>
                            </div>
                        </div>
                        {/* <div className='team-description'>
                            <img src={AVATAR} alt="claps-media-image"></img>
                            <div>
                                <p>
                                    CLAPS MEDIA is an advertising film company comprising a team of talented and dedicated
                                    professionals, we have the experience and expertise needed to create effective ads of great quality.
                                </p>
                                <i className='fa fa-vimeo-square'></i>
                                <i className='fa fa-linkedin-square'></i>
                                <i className='fa fa-youtube-play'></i>
                                <i className='fa fa-twitter-square'></i>
                                <i className='	fa fa-instagram'></i>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='gallery-section' id='gallery'>
                <div className='gallery-box'>
                    <h1 >Articles</h1>
                    <hr className='hr'></hr>
                    <div className='gallery-inner-box'>
                        <div className='gallery-card'>
                            <img className='gallery-img' src={POSTER1} alt='articel1'></img>
                        </div>
                        <div className='gallery-card'>
                            <img className='gallery-img' src={POSTER2} alt='articel1'></img>
                        </div>
                        <div className='gallery-card'>
                            <img className='gallery-img' src={DUMMYIMG} alt='articel1'></img>
                        </div>
                        <div className='gallery-card'>
                            <img className='gallery-img' src={ARTICLE1} alt='articel1'></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact-section' id='contact'>
                <div className='contact-box'>
                    <h1>Get In Touch!</h1>
                    <hr className='hr'></hr>
                    <div className='contact-sub-heading'>
                        <p className='mb-0'>Swing by for a cup of <i className='fa fa-coffee'></i>, or leave me a note:</p>
                    </div>
                    <div className='contact-inner-box'>
                        <div className='contact-card'>
                            <i class='fa fa-map-marker'></i>
                            <span className='contact-text'>NCR - New Delhi, India</span>
                        </div>
                        <div className='contact-card'>
                            <i class='fa fa-mobile-phone'></i>
                            <span className='contact-text'>+91 8755761776</span>
                        </div>
                        <div className='contact-card'>
                            <i class='fa fa-envelope-o'></i>
                            <span className='contact-text'>contant@fashionlawyerindia.com</span>
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    <i className='fa fa-copyright'></i> 2024 - Fashion Lawyer India
                </div>
            </div>
        </div >
    )
}
export default HompageComponent

